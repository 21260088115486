<template>
    <div>
        <b-row>
            <b-col cols="12" v-if="guide == 'hospitalization-request'">
                <p class="form-title mb-3">Procedimentos ou Itens Assistenciais Solicitados</p>
            </b-col>

            <b-col cols="2" >
                <label for="table" id="title-styles">
                    Tabela
                </label>
                <b-form-input
                  autocomplete="off"
                  :placeholder="tablePlaceholder"
                  class="form-control"
                  readonly
                />
                <div v-if="validated && !form.table_name" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="3" class="grid">
                <label for="procedure_id" id="title-styles" class="inline-text">
                  Cód. do procedimento ou item
                </label>
                <multiselect
                  v-model="form.procedureItem"
                  placeholder="Selecionar"
                  :options="values"
                  label="label"
                  @search-change="getProcedureMaterialDebounce"
                  @select="selectValue"
                  :option-height="40"
                  :allow-empty="true"
                  :showLabels="false"
                  :searchable="true"
                  :internal-search="false"
                  class="with-border"
                  :disabled="readonly"
                  track-by="code"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span class="d-inline-block text-truncate w-75">
                      {{ option?.code }}
                    </span>
                  </template>
                  <template slot="noOptions">
                    Digite para pesquisar procedimento
                  </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div v-if="validated && !form.procedure" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" >
                <label for="description" id="title-styles">
                    Descrição
                </label>
                <b-form-input
                    placeholder="Selecione o procedimento"
                    maxlength="150"
                    autocomplete="off"
                    class="form-control"
                    v-model="form.description"
                    readonly
                />
                <div v-if="validated && !form.description" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" >
                <label for="requested_quantity" id="title-styles">
                  Qtd. Solic.
                </label>
                <multiselect
                  v-model="form.requested_quantity"
                  placeholder="Selecionar"
                  :options="quantities"
                  :option-height="40"
                  :showLabels="false"
                  :allow-empty="true"
                  :searchable="true"
                  :internal-search="false"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <div v-if="validated && !form.requested_quantity" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-col>

            <b-col cols="2" >
                <label for="authorized_quantity" id="title-styles">
                  Qtd. Aut.
                </label>
                <multiselect
                  v-model="form.authorized_quantity"
                  placeholder="Selecionar"
                  :options="quantities"
                  :option-height="40"
                  :showLabels="false"
                  :allow-empty="true"
                  :searchable="true"
                  :internal-search="false"
                  class="with-border"
                  :disabled="readonly"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noResult"> Nenhum resultado </template>
                </multiselect>
                <!-- <div v-if="validated && !form.authorized_quantity" class="custom-invalid-feedback">Campo obrigatório</div> -->
            </b-col>

            <b-col cols="1">
              <b-button
                v-if="editIndex != null"
                variant="primary"
                class="btn-procedure"
                @click="updateProcedure"
              >
                Alterar
              </b-button>
              <b-button
                :disabled="readonly"
                v-else
                variant="primary"
                class="btn-procedure"
                @click="addProcedure"
              >
                Adicionar
              </b-button>
            </b-col>
        </b-row>

        <b-row
            v-if="materialsProcedures && materialsProcedures.length > 0"
            class="mt-2"
        >
            <b-table-simple striped>
                <tbody>
                    <tr class="tr-header">
                      <th scope="col">Tabela</th>
                      <th scope="col">Cod. do procedimento ou item</th>
                      <th scope="col" width="33%" class="text-center">Descrição</th>
                      <th scope="col" class="text-center">Qtde. Solic.</th>
                      <th scope="col" class="text-center">Qtde. Aut.</th>
                      <th scope="col"></th>
                    </tr>

                    <tr v-for="(materialsProcedure, index) of materialsProcedures" :key="index" class="tr-header">
                        <td>
                          <span v-if="materialsProcedure.table_name == '22'">TUSS - Procedimentos e eventos em saude</span>
                          <span v-else-if="parseInt(materialsProcedure.table_name) == 0">TUSS - Tabela própria das operadoras</span>
                          <span v-else>Tabela {{ materialsProcedure.table_name }}</span>
                        </td>
                        <td>{{ materialsProcedure.item?.procedure?.code  ?? materialsProcedure.item?.code }}</td>
                        <td class="text-center">{{ materialsProcedure.description }}</td>
                        <td class="text-center">{{ materialsProcedure.requested_quantity }}</td>
                        <td class="text-center">{{ materialsProcedure.authorized_quantity }}</td>
                        <td class="text-right d-flex">
                          <Edit
                            v-if="!readonly"
                            class="edit mr-1"
                            @click="editProcedure(materialsProcedure, index)"
                          />
                          <Delete
                            v-if="!readonly"
                            @click="deleteProcedure(materialsProcedure, index)"
                          />
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </b-row>

        <b-row>
            <b-col cols="12">
                <hr id="hr" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getCurrentClinic } from '@/utils/localStorageManager';
import api from '@items/api'

export default {
    name: 'MaterialsProceduresModule',
    components: {
      Delete: () => import('@/assets/icons/delete.svg'),
      Edit: () => import('@/assets/icons/edit.svg'),
      ChevronDown: () => import('@/assets/icons/chevron-down.svg')
    },
    props: {
      materialsProcedures: Array,
      clinicHealthPlanId: String,
      allowedTypes: { 
          type: Array, 
          required: true 
      },
      guide: String,
      settingsId: String,
      readonly: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.getProcedureMaterialDebounce = debounce(this.searchItems, 500)
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        form: this.getDefaultForm(),
        validated: false,
        values: [],
        editIndex: null,
        item: {},
        quantities: Array.from({length: 10}, (_, i) => i + 1)
      }
    },
     computed: {
      tablePlaceholder() {
        return {
          '00': 'TUSS - Tabela própria das operadoras',
          '19': 'TUSS - Materiais',
          '20': 'TUSS - Medicamentos',
          '22': 'TUSS - Procedimentos e eventos em saude'
        }[this.form.table_name] || this.form?.table_name
      },
    },
    methods: {
      getDefaultForm() {
        return {
          item: null,
          table_name: null,
          procedure_id: null,
          description: null,
          requested_quantity: null,
          authorized_quantity: null
        }
      },
      searchItems(query) {
        if (query.length < 3) return
        this.loading = true;
        api.searchItems(this.clinic.id, query, this.allowedTypes, this.clinicHealthPlanId)
          .then(({ data }) => {
              this.values = Object.values(data).map(item => ({
                value: item.code,
                label: `${item.code} -  ${item.name}`,
                procedure: item
              }));
            // this.form.procedureItem = data
          })
          .catch(err => {
              this.$toast.error(err.message)
              console.error(err)
          })
          .finally(() => (this.loading = false));
      },
      isValidForm() {
        this.validated = true;
        return Boolean(this.form.table_name &&
          this.form.procedure_id &&
          this.form.description &&
          this.form.requested_quantity)
      },
      addProcedure() {
        if(!this.isValidForm()) return;
        
        this.materialsProcedures.push({
          'item': this.form.procedureItem,
          'item_id': this.form.procedureItem.id,
          'table_name': this.form.procedureItem?.health_plan_props?.table_number || '00',
          'procedure_id': this.form.procedure_id,
          'description': this.form.description,
          'requested_quantity': this.form.requested_quantity,
          'authorized_quantity': this.form.authorized_quantity
        });
        this.form = this.getDefaultForm()
        this.validated = false
      },
      async findTissTableProcedure(procedureId) {
        if (!procedureId) return
        try {
          const {data} = await this.api.findTissTableProcedureById(procedureId)
          this.values = [{
            value: data?.procedure?.code,
            label: `${data?.procedure?.code} -  ${data?.procedure?.name}`,
            procedure: data?.procedure
          }]
          this.form.procedureItem = data
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      async getProcedureMaterial(query) {
        if (query.length < 3) return
        try {
          const { data } = await this.api.getTissTableProcedures(this.settingsId, query)
          this.values = data.map(item => {
            return {
              value: item.procedure.code,
              label: `${item.procedure.code} -  ${item.procedure.name}`,
              procedure: item
            }
          })
        } catch (error) {
          this.$toast.error(error.message)
        }
      },
      selectValue(value) {
        const item = value.procedure
        this.form.procedureItem = item
        this.form.procedure_id = item?.id
        this.form.description = item?.name
        this.form.table_name = item?.health_plan_props?.table_number

        this.values = []
      },
      editProcedure(procedure, index) {
        if (procedure.id) {
          this.form.id = procedure.id
        }
        this.form.procedureItem = {
          id: procedure.item.id,
          value: procedure.item.code,
          label: procedure.item.name,
          code: procedure.item.code
        }
        // this.findTissTableProcedure( this.form?.procedureItem?.id)
        this.form.table_name = procedure?.health_plan_props?.table_number
        this.form.procedure_id = procedure.item?.procedure?.id ?? (procedure.procedure_id ?? procedure.item?.id)
        this.form.description = procedure.description
        this.editIndex = index
        this.$set(this.form, 'authorized_quantity', String(procedure.authorized_quantity))
        this.$set(this.form, 'requested_quantity', String(procedure.requested_quantity))
      },
      updateProcedure() {
        if(!this.isValidForm() || this.editIndex === null) return;
        const values = {
          'item': this.form.procedureItem,
          'item_id': this.form.procedureItem.id,
          'table_name': this.form.procedureItem?.health_plan_props?.table_number || '00',
          'procedure_id': this.form.procedureItem.id,
          'description': this.form.description,
          'requested_quantity': this.form.requested_quantity,
          'authorized_quantity': this.form.authorized_quantity
        }

        if (this.form.id) {
          values.id = this.form.id
        }
        this.$set(this.materialsProcedures, this.editIndex, values)

        this.form = this.getDefaultForm()
        this.validated = false
        this.editIndex = null
      },
      async deleteProcedure(procedure, index) {
        if (procedure.id) {
          const isLoading = this.$loading.show()
          try {
            await this.api.deleteTissGuideMaterialProcedure(procedure.id)
            this.materialsProcedures.splice(index, 1)
            this.$toast.success('Procedimento excluído com sucesso!')
          } catch (error) {
            this.$toast.error(error.message)
          } finally {
            isLoading.hide()
          }
        } else {
          this.materialsProcedures.splice(index, 1)
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.form-title{
  color: var(--neutral-600);
  font-size: 18px;
  font-weight: bolder;
  line-height: 28px;
}

.inline-text {
  width: 28ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.grid {
  display: grid;
}

.edit {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    cursor: pointer;
}

.delete {
    width: 24px;
    height: 24px;
    stroke: var(--red-500);
    cursor: pointer;
}

.table th, .table td {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
}

.tr-header {
  th:first-child {
    padding-left: 16px;
  }

  .table th, .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td:first-child {
    padding: 16px 50px 16px 16px !important;
    max-width: 25ch;
  }
}

th {
  font-size: 14px;
  color: var(--type-active);
}

.td {
  padding: 0.75rem !important;
}

#title-styles {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #0a184c;
  margin: 0;
}

#hr {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.btn-procedure {
  margin-top: 24px;
  margin-left: -13%;
  width: 90px;
  height: 38px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.width-85 {
    width: 85% !important;
  }

</style>
